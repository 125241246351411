import { api } from '@/services'
import { defineStore } from 'pinia'
import { ref } from 'vue'

const useCompanyCoursesStore = defineStore('companyCourses', () => {
  const cachedCourses = ref({ companyId: null })
  const coursesList = ref([])

  function updateCompanyCoursesList(updatedCompanyCourse) {
    coursesList.value.forEach((companyCourse, index) => {
      if (updatedCompanyCourse.id === companyCourse.id) {
        coursesList.value[index] = updatedCompanyCourse
      }
    })
  }
  async function fetchCompanyCourses(companyId) {
    if (cachedCourses.value.companyId === companyId) {
      return
    }

    const companyCourses = await api.get(
      `/api/maintenance/companies/${companyId}/courses?per_page=100`
    )
    coursesList.value = companyCourses.data.data
    cachedCourses.value = { companyId }
  }
  return { coursesList, updateCompanyCoursesList, fetchCompanyCourses }
})
export default useCompanyCoursesStore
