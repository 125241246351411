import { useUserStore, useCompaniesStore } from '@/common/stores'

export const redirectNonMaintainers = async (to, _from, next) => {
  const companiesStore = useCompaniesStore()
  const userStore = useUserStore()
  await userStore.decodeUserJwt()

  if (userStore.isAdminUser) {
    next()
    return
  }

  await companiesStore.fetchCompanyList(userStore.userId)

  const currentPlatformId = companiesStore.getCompanyPlatformIdById(
    to.params.companyId
  )

  const userRolesForCurrentCompany = userStore.userData.roles[currentPlatformId]
  const isMaintainer = userRolesForCurrentCompany.includes('academy_maintainer')

  if (!isMaintainer && !userStore.isAdminUser) {
    next({ name: 'Dashboard', params: to.params })
    return
  }

  next()
}
